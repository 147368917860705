import "./LandingPage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import upcomingEvents01 from "../../../Assets/Images/upcoming_events_01.png";
import upcomingEvents06 from "../../../Assets/Images/upcoming_events_06.png";
import upcomingEvents07 from "../../../Assets/Images/upcoming_events_07.png";

function LandingPage() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
    };

    const gridItems = [
        {
            title: "Miami Open Ritmo Social VIP",
            description: "Experience vibrant Latin culture at Miami Open",
            image: upcomingEvents01,
        },
        {
            title: "Miami Race Weekend at Hard Rock Stadium",
            description: "Aiming to bring Latin flavor to Miami's biggest race",
            image: upcomingEvents06,
        },
        {
            title: "Holika Gran Reserva Festival Miami",
            description: "Reggaeton & Pop Paradise",
            image: upcomingEvents07,
        },
    ];

    return (
        <div className="landing-page">
            <Slider {...settings} className="carousel">
                {gridItems.map((item, index) => (
                    <div key={index} className={`slide slide${index + 1}`}>
                        <div className="slide-content">
                            <h2>{item.title}</h2>
                            <p>{item.description}</p>
                        </div>
                    </div>
                ))}
            </Slider>
            <div className="grid-section">
                <h2>Upcoming Events</h2>
                <div className="landing-page-grid-container">
                    {gridItems.map((item, index) => (
                        <div key={index} className="grid-item">
                            <img src={item.image} alt={item.title} />
                            <div className="grid-item-content">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
